import React, { useState, useEffect } from "react";
import ApexBarMarkerChart from "../subcomponents/chart-components/ApexBarMarkerChart";
import PublishedAuditReports from "../subcomponents/PublishedAuditReports";
import {
  mostInquiriedReceivedByDurationAndType,
  mostactiveproductssearched,
  inquiriesReceived,
  mostactiveusers,
} from "../../services/analytics";
import { toast } from "react-toastify";

function VendorReport() {
  const [duration, setDuration] = useState(3); // Default to 3 months

  const [mostactiveproductssearchedstate, setMostactiveproductssearchedstate] =
    useState([]);
  const [mostInquiredReceivedForProduct, setMostInquiredReceivedForProduct] =
    useState([]);
  const [mostInquiriesReceivedFromBuyer, setMostInquiriesReceivedFromBuyer] =
    useState([]);
  const [
    mostInquiriesReceivedFromManufacturer,
    setMostInquiriesReceivedFromManufacturer,
  ] = useState([]);
  const [mostactiveusersstate, setMostactiveusersstate] = useState([]);
  const [countOfinquiriesReceivedData, setCountOfinquiriesReceivedData] =
    useState([]);

  useEffect(() => {
    mostInquiriedReceivedByDurationAndType(duration, "product")
      .then((res) => {
        setMostInquiredReceivedForProduct(res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    mostInquiriedReceivedByDurationAndType(duration, "user")
      .then((res) => {
        setMostInquiriesReceivedFromBuyer(res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    mostInquiriedReceivedByDurationAndType(duration, "manufacturer")
      .then((res) => {
        setMostInquiriesReceivedFromManufacturer(res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    mostactiveproductssearched(duration, "product")
      .then((res) => {
        setMostactiveproductssearchedstate(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });

    inquiriesReceived(duration)
      .then((res) => {
        setCountOfinquiriesReceivedData(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });

    mostactiveusers(duration, "user")
      .then((res) => {
        setMostactiveusersstate(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, [duration]);

  const handleDurationChange = (months) => {
    setDuration(months);
    toast.success("Duration updated to " + months + " months");
  };

  return (
    <>
      <section id="adminreport" className="section  p-0">
        <div className="row mb-3">
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <div
              className="btn-group"
              role="group"
              aria-label="Select Duration"
            >
              {[3, 6, 9, 12].map((months) => (
                <button
                  key={months}
                  type="button"
                  className={`btn custom-btn ${
                    duration === months ? "" : "selected-duration"
                  }`}
                  onClick={() => handleDurationChange(months)}
                >
                  {months} Months
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title fw-bold mb-3">
                  No. of inquiries received
                </h5>
                {/* <ApexStackedColumnChart /> */}
                <ApexBarMarkerChart
                  countOfinquiriesMadeData={countOfinquiriesReceivedData}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-2">
                <h5 className="card-title fw-bold mb-3">
                  Most inquiries received from Buyer name/Country
                </h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Country</th>
                      {/* <th scope="col">Job Role</th> */}
                      <th scope="col">Inquiry Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostInquiriesReceivedFromBuyer.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <h6 className="my-2"> No data available</h6>
                        </td>
                      </tr>
                    ) : (
                      mostInquiriesReceivedFromBuyer.map((item, index) => (
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td>{item.company}</td>
                          <td>{item.country}</td>
                          {/* <td>{item.jobRole}</td> */}
                          <td>{item.inquiryCount}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-2">
                <h5 className="card-title fw-bold mb-3">
                  Most inquiries made for product
                </h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">Inquiry Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostInquiredReceivedForProduct.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <h6 className="my-2"> No data available</h6>
                        </td>
                      </tr>
                    ) : (
                      mostInquiredReceivedForProduct.map((item, index) => (
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.country}</td>
                          <td>{item.inquiryCount}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {/* <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">
                  Number of plans bought (Plan 1, 2 and 3)
                </h5>
                <ApexBarMarkerChart />
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-3 0 my-3 mx-2">
                <h5 className="card-title fw-bold mb-3">
                  Most inquiries made for the manufacturer
                </h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Manufacturer</th>
                      <th scope="col">Country</th>
                      <th scope="col">Inquiry Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostInquiriesReceivedFromManufacturer.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <h6 className="my-2"> No data available</h6>
                        </td>
                      </tr>
                    ) : (
                      mostInquiriesReceivedFromManufacturer.map(
                        (item, index) => (
                          <tr key={index}>
                            <td scope="row">{index + 1}</td>
                            <td>{item.manufacturer}</td>
                            <td>{item.country}</td>
                            <td>{item.inquiryCount}</td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-2">
                <h5 className="card-title fw-bold mb-3">
                  Most active products searched for
                </h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col" className="col-1">
                        S. No.
                      </th>
                      <th scope="col" className="col">
                        Query
                      </th>
                      <th scope="col" className="col">
                        Search Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostactiveproductssearchedstate.map((item, index) => (
                      <tr key={index + 1}>
                        <td scope="row" className="col-1 text-left">
                          {index + 1}
                        </td>
                        <td className="col-5 text-left">
                          {item.query || "Empty query"}
                        </td>
                        <td className="col-5 text-left">{item.searchCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title fw-bold mb-3">
                  Most active buyer names
                </h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius table-hover text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">Login Count</th>
                      <th scope="col" className="text-left">
                        Job Role
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostactiveusersstate.map((item, index) => (
                      <tr key={index + 1}>
                        <td scope="row" className="col-1 text-left">
                          {index + 1}
                        </td>
                        <td className="col-2 text-left">{item.name}</td>
                        <td className="col-2 text-left">{item.country}</td>
                        <td className="col-1 text-left">{item.loginCount}</td>
                        <td className="col-1 text-left">{item.jobRole}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <PublishedAuditReports publishedReportCount={0} />
          </div>
        </div>
      </section>
    </>
  );
}

export default VendorReport;
