import React from "react";
import PackageOption from "../subcomponents/PackageOption";

const MainPricingSection = ({ plans, handleBuy }) => {
  return (
    <>
      <section id="pricing" className="pricing">
        <div className="container">
          <h5 className="fw-bold mb-lg-4">Available plans</h5>
          <div className="row" data-aos="fade-left">
            {plans.map((plan, index) => (
              <PackageOption
                key={plan.id}
                title={plan.name}
                europrice={plan.currency === "EUR" ? plan.price : null}
                inrprice={plan.currency === "INR" ? plan.price : null}
                bullets={[
                  "Credits - " + plan.credits,
                  "Currency - " + plan.currency,
                  "Validity - " +
                    (plan.duration ? plan.duration + " days" : "Unlimited"),
                ]}
                className={`box featured`}
                handleBuy={() => handleBuy(plan)}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainPricingSection;
