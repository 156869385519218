import React, { useState } from "react";
import { getAttributeInLocalStorageData } from "../../utils";
import { toast } from "react-toastify";
import { sendPlanAuditInquiry } from "../../services/plan";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../utils";

const PlanNewAuditIntro = () => {
  const sectionStyle = {
    position: "relative",
    height: "auto",
  };

  const [values, setValues] = useState({
    name: "",
    email: "",
    country: "",
    company: "",
    nameOfFacility: "",
    unitNumber: "",
    location: "",
    productName: "",
  });

  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const userRole = getAttributeInLocalStorageData("role");
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundImage: `abcdurl(${process.env.PUBLIC_URL}/assets/gif/librarygif1.gifsdf)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 1, // Adjust the opacity as needed
    // backgroundColor: "rgb(27 204 141 / 7%)",
  };

  const resetForm = () => {
    setValues({
      name: "",
      email: "",
      country: "",
      company: "",
      nameOfFacility: "",
      unitNumber: "",
      location: "",
      productName: "",
    });
  };

  const handleCloseDialog = () => {
    // setMessage("");
    setShowDialog(false);
  };

  const isFormValid = (values) => {
    if (!values.name || !values.email) {
      toast.error("Name and Email are required");
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(values.email)) {
      toast.error("Invalid email format");
      return false;
    }
    return true;
  };

  const handleSendEnquiry = () => {
    console.log("entered handleSendEnquiry", values);
    if (isFormValid(values)) {
      setShowDialog(false);
      const sendInquiryPromise = sendPlanAuditInquiry(values)
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          console.error("Error details:", error); // This will log the error object
          return Promise.reject(error); // Forward the error for toast handling
        });
      toast.promise(sendInquiryPromise, {
        pending: "Your request is in progress",
        success:
          "Your inquiry was successfully submitted. \n An email was sent to you and the audit service providers.",
        error: {
          render({ data }) {
            // `data` will be the error object
            return `Failed: ${data.message || "Unknown error"}`;
          },
        },
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleButtonClick = (e) => {
    // navigate("/profile#browse-audit-report");
    e.preventDefault();
    if (userRole === "user") {
      setShowDialog(true);
    } else {
      navigate("/login");
    }
  };

  return (
    <section
      className="plannewauditintro position-relative "
      id="plannewauditintro"
      style={sectionStyle}
    >
      {/* Background GIF */}
      <div
        className="mbr-background-video isMuted"
        style={backgroundStyle}
      ></div>
      {/* <div
       className="mbr-overlay"
        style={{ opacity: "0.4", backgroundColor: "rgb(0, 0, 0)" }}
      ></div> */}
      <div className="align-center mx-5 py-5 section-custom">
        <div className="row justify-content-center ">
          <div className="col-md-3" data-aos="fade-right">
            <img
              src={"assets/img/tab/Tab 3 image.jpg"}
              className="img-fluid img-fluid rounded"
              alt=""
            />
          </div>
          <div
            className=" col-md-6 content d-flex justify-content-center align-items-center flex-direction-column"
            data-aos="fade-left"
          >
            <h3 className="align-left  pb-3 ">Plan A New Audit</h3>
            <p className="mbr-text align-left pb-4 text-align-center">
              Plan a new audit with the best audit service providers to save
              time and cost.
            </p>
            <div className="mbr-section-btn align-left d-flex">
              <button
                className="btn btn-md btn-danger rounded-pill px-4 py-2 display-4 btn-hover-effect"
                onClick={handleButtonClick}
                disabled={isLoggedIn && userRole === "vendor"}
              >
                Click here
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDialog && (
        <div
          className="modal blur"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Plan A New Audit</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleCloseDialog}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Name * "
                      name="name"
                      value={values.name}
                      required
                      autoFocus
                    />
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Country"
                      name="country"
                      value={values.country}
                    />
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.nameOfFacility}
                    />
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Location"
                      name="location"
                      value={values.location}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Email *"
                      name="email"
                      value={values.email}
                      required
                    />
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Company"
                      name="company"
                      value={values.company}
                    />
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Unit Number"
                      name="unitNumber"
                      value={values.unitNumber}
                    />
                    <input
                      className="col-12 border-2 my-2 rounded-3 py-1 ps-2"
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Product Name"
                      name="productName"
                      value={values.productName}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleSendEnquiry}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PlanNewAuditIntro;
