import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../services/auth";
import Header from "../../Header";

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    // Extract token from query parameter
    const token = new URLSearchParams(location.search).get("token");

    setFormData({ ...formData, token: token });
  }, [formData, location.search]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    resetPassword(formData)
      .then((res) => {
        if (res.status === true) {
          toast.success("Password Reset Successful");
          navigate("/login");
        } else {
          navigate("/expired");
          toast.error("Password Reset Failed");
        }
      })
      .catch((error) => {
        navigate("/expired");
        toast.error(error);
      });
  };

  return (
    <>
      <Header showBackground={false} />
      <section
        id="forgot-password"
        className="background-radial-gradient overflow-hidden"
      >
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0 position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="password">
                        Enter New Password
                      </label>
                      <input
                        type="password"
                        required
                        id="password"
                        className="form-control"
                        name="password"
                        minLength={9}
                        maxLength={20}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$"
                        title="Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 9 characters long."
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="confirmPassword">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        required
                        id="confirmPassword"
                        className="form-control"
                        name="confirmPassword"
                        pattern={`^${formData.password}$`}
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <!-- Submit button --> */}
                    <button
                      type="submit"
                      className="btn btn-danger btn-block mb-4"
                    >
                      Reset Password
                    </button>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
