import axios from "axios";
import API_URL from "../config/api";

export const planCreationService = (plan) => {
  return axios
    .post(API_URL.CREATE_PLAN, plan)
    .then((res) => {
      console.log(res.data);
      return "Plan Published Successfully";
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Plan Publish failed";
      return Promise.reject(errorMessage);
    });
};

export const fetchallplans = () => {
  return axios
    .get(API_URL.GET_ALL_PLANS)
    .then((res) => {
      console.log("Plans fetched successfully", res.data.data);
      return res.data.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "Plans fetch failed";
      return Promise.reject(errorMessage);
    });
};

export const purchasePlan = (planid,couponcode) => {
  return axios
    .post(`/plan/id/${planid}/purchase`, { "code": couponcode })
    .then((res) => {
      console.log("response of purchase plan " + res.data);
      
      return res.data;
      // return (window.location.href = res.data.sessionUrl);
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Plan Purchase failed";
      return Promise.reject(errorMessage);
    });
};

export const sendInquiry = (data) => {
  return axios
    .post(API_URL.SEND_QUERY, data)
    .then((res) => {
      console.log(res.data);
      return "Inquiries sent Successfully";
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Inquiries sent failed";
      return Promise.reject(errorMessage);
    });
};

export const sendPlanAuditInquiry = (data) => {
  return axios
    .post(API_URL.PLAN_NEW_AUDIT, data)
    .then((res) => {
      console.log(res.data);
      return "Inquiries sent Successfully";
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Inquiries sent failed";
      return Promise.reject(errorMessage);
    });
};


