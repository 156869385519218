import React, { useState, useEffect } from "react";
import ApexLineChart from "../subcomponents/chart-components/ApexLineChart";
import ApexBarMarkerChart from "../subcomponents/chart-components/ApexBarMarkerChart";
import {
  buyersRegisteredStats,
  vendorsRegisteredStats,
  mostactiveusers,
  mostactiveproductssearched,
  countofReportsUploaded,
  mostactivevendors,
} from "../../services/analytics";
import { toast } from "react-toastify";

function AdminReport() {
  const [mostactiveproductssearchedstate, setMostactiveproductssearchedstate] =
    useState([]);
  const [mostactiveusersstate, setMostactiveusersstate] = useState([]);
  const [buyersregisteredstatscount, setBuyersregisteredstatscount] = useState(
    []
  );
  const [buyersregisteredstatsmonth, setBuyersregisteredstatsmonth] = useState(
    []
  );
  const [vendorsregisteredstatscount, setVendorsregisteredstatscount] =
    useState([]);
  const [vendorsregisteredstatsmonth, setVendorsregisteredstatsmonth] =
    useState([]);
  const [countofReportsUploadedStats, setCountofReportsUploadedStats] =
    useState([]);
  const [mostactivevendorsList, setmostactivevendorsList] = useState([]);

  const [duration, setDuration] = useState(3); // Default to 3 months

  useEffect(() => {
    buyersRegisteredStats(duration)
      .then((res) => {
        const counts = [];
        const months = [];

        res.data.forEach(({ count, month }) => {
          counts.push(count);
          months.push(month);
        });
        setBuyersregisteredstatscount(counts);
        setBuyersregisteredstatsmonth(months);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    vendorsRegisteredStats(duration)
      .then((res) => {
        const counts = [];
        const months = [];

        res.data.forEach(({ count, month }) => {
          counts.push(count);
          months.push(month);
        });
        setVendorsregisteredstatscount(counts);
        setVendorsregisteredstatsmonth(months);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    mostactiveusers(duration, "manufacturer")
      .then((res) => {
        setMostactiveusersstate(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });

    mostactiveproductssearched(duration, "product")
      .then((res) => {
        setMostactiveproductssearchedstate(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });

    countofReportsUploaded(duration)
      .then((res) => {
        setCountofReportsUploadedStats(res.data);
        console.log("props.setCountofReportsUploadedStats " + res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    mostactivevendors(duration)
      .then((res) => {
        setmostactivevendorsList(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, [duration]);

  const handleDurationChange = (months) => {
    setDuration(months);
    toast.success("Duration updated to " + months + " months");
  };

  return (
    <>
      <section id="adminreport" className="section p-0">
        <div className="row mb-3">
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <div
              className="btn-group"
              role="group"
              aria-label="Select Duration"
            >
              {[3, 6, 9, 12].map((months) => (
                <button
                  key={months}
                  type="button"
                  className={`btn custom-btn ${
                    duration === months ? "" : "selected-duration"
                  }`}
                  onClick={() => handleDurationChange(months)}
                >
                  {months} Months
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">Number of Buyers Registered</h5>
                <ApexLineChart
                  xaxisctg={buyersregisteredstatsmonth}
                  data={buyersregisteredstatscount}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">
                  Number of audit service providers registered
                </h5>
                <ApexLineChart
                  xaxisctg={vendorsregisteredstatsmonth}
                  data={vendorsregisteredstatscount}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">Number of audit reports uploaded</h5>
                <ApexBarMarkerChart
                  countOfinquiriesMadeData={countofReportsUploadedStats}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">Most active products searched</h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius table-hover text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col text-left">S. No.</th>
                      <th scope="col">Query</th>
                      <th scope="col">Search Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostactiveproductssearchedstate.map((item, index) => (
                      <tr key={index + 1}>
                        <td scope="row" className="col-1 text-left">
                          {index + 1}
                        </td>
                        <td className="col-1 ">{item.query}</td>
                        <td className="col-1 ">{item.searchCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>

            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">Most active buyer names</h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius table-hover text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">Login Count</th>
                      <th scope="col">Job Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostactiveusersstate.map((item, index) => (
                      <tr key={index + 1}>
                        <td scope="row" className="col-1 text-left">
                          {index + 1}
                        </td>
                        <td className="col-1 text-left">{item.name}</td>
                        <td className="col-1 text-left">{item.country}</td>
                        <td className="col-1 text-left">{item.loginCount}</td>
                        <td className="col-1 text-left">{item.jobRole}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>
            <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">
                  Most active audit service provider names
                </h5>

                {/* <!-- Default Table --> */}
                <table className="table box-shadow-border-radius table-hover text-left table-common-font-size">
                  <thead>
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">Login Count</th>
                      <th scope="col">Job Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostactivevendorsList.map((item, index) => (
                      <tr key={index + 1}>
                        <td scope="row" className="col-1 text-left">
                          {index + 1}
                        </td>
                        <td className="col-1 text-left">{item.name}</td>
                        <td className="col-1 text-left">{item.country}</td>
                        <td className="col-1 text-left">{item.loginCount}</td>
                        <td className="col-1 text-left">{item.jobRole}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <!-- End Default Table Example --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* Subscribed Details Table */}
            {/* <SubscribedDetails subscribedDetailsData={subscribedDetailsData} /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="card">
              <div className="card-body p-3 0 my-3 mx-12">
                <h5 className="card-title">
                  Number of plans bought (Plan 1, 2 and 3)
                </h5>
                <ApexStackedColumnChart />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminReport;
