import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const isNotHomePage = location.pathname !== "/";
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/admin/login";
  const isRegisterPage =
    location.pathname === "/user/register" ||
    location.pathname === "/vendor/register";
  const forgotpassword = location.pathname === "/forgot-password";
  const isContactUs = location.pathname === "/contact-us";
  return (
    ((isContactUs || isLoginPage || isRegisterPage || forgotpassword) && (
      <footer id="footer">
        <div className="container ">
          <div className="footer-menu-container d-flex justify-content-around py-5 footer-left-right-padding">
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to="/about-us">About Us</Link>
            </div>
            <div>
              <Link to="/contact-us">Contact</Link>
            </div>
          </div>

          <div className="copyright d-flex justify-content-center pb-3 ">
            © 2025 by&nbsp;
            <strong>
              <span>GMPAuditReports</span>
            </strong>
          </div>
        </div>
      </footer>
    )) ||
    (isNotHomePage && (
      <footer
        id="footer"
        className="mt-3"
        style={{ backgroundColor: "rgb(239, 68, 38)" }}
      >
        <div className="container ">
          <div className="d-flex justify-content-around py-5 footer-left-right-padding">
            <div>
              <Link to="/" style={{ color: "white" }}>
                Home
              </Link>
            </div>
            <div>
              <Link to="/about-us" style={{ color: "white" }}>
                About Us
              </Link>
            </div>
            <div>
              <Link to="/contact-us" style={{ color: "white" }}>
                Contact
              </Link>
            </div>
          </div>

          <div
            className="copyright d-flex justify-content-center pb-3 "
            style={{ color: "white" }}
          >
            © 2025 by&nbsp;
            <strong>
              <span>GMPAuditReports</span>
            </strong>
          </div>
        </div>
      </footer>
    ))
  );
}

export default Footer;
