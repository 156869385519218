export const getToken = () => {
  if (localStorage.getItem("data")) {
    return JSON.parse(localStorage.getItem("data")).token;
  } else {
    return null;
  }
};

export const setAttributeInLocalStorageData = (
  attributeName,
  attributeValue
) => {
  const existingData = JSON.parse(localStorage.getItem("data")) || {};
  existingData[attributeName] = attributeValue;

  localStorage.setItem("data", JSON.stringify(existingData));
};

export const getAttributeInLocalStorageData = (attributeName) => {
  const existingData = JSON.parse(localStorage.getItem("data")) || {};
  if (attributeName === "data") {
    return existingData;
  }
  return existingData[attributeName];
};

export const isAuthenticated = () => {
  // if (typeof window === "undefined") {
  //   return false;
  // }
  console.log("isAuthenticated", !!localStorage.getItem("data"));
  return !!localStorage.getItem("data"); // Return true if data exists, false if it doesn't
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key) => {
  let value = localStorage.getItem(key);
  if (value) return JSON.parse(value);
  else return null;
};

export const deleteFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const alertWithMessage = (message) => {
  window.alert(message);
};

export const confirmWithMessage = (message) => {
  return window.confirm(message);
};

export const promptWithMessage = (message) => {
  return window.prompt(message);
};

export const formatNumberWithCommas = (
  number,
  locales = "en-IN",
  currency = "INR"
) => {
  return new Intl.NumberFormat(locales, {
    maximumSignificantDigits: 4,
    style: "currency",
    currency: currency,
  }).format(number);
};

export const isEmail = (email) => {
  return email;
};
