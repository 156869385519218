import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import UserRegistrationPage from "./components/pages/UserRegistrationPage";
import VendorRegistrationPage from "./components/pages/VendorRegistrationPage";
import ItemSearch from "./components/pages/ItemSearch";
import AdminReport from "./components/pages/AdminReport";
import VendorReport from "./components/pages/VendorReport";
import ProfilePage from "./components/pages/ProfilePage";
import UserReport from "./components/pages/UserReport";
import ContactPage from "./components/pages/ContactPage";
import PricingPage from "./components/pages/PricingPage";
import AboutUsPage from "./components/pages/AboutUsPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AdminLoginPage from "./components/pages/AdminLoginPage";
import ScrollToTop from "./components/utilcomponents/ScrollToTop";
import VerifyEmail from "./components/subcomponents/utility/VerifyEmail";
import { AuthProvider } from "./components/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import PaymentSuccessful from "./components/subcomponents/payment-components/PaymentSuccessful";
import PaymentFail from "./components/subcomponents/payment-components/PaymentFail";
import PageNotFound from "./components/subcomponents/utility/PageNotFound";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/subcomponents/utility/ResetPassword";
import Expired from "./components/subcomponents/utility/Expired";
import { isAuthenticated } from "./utils";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Router>
        {/* <Header /> */}
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              exact
              element={<HomePage showBackground={false} />}
            />
            <Route
              path="/login"
              exact
              element={
                isAuthenticated() ? (
                  <Navigate to="/profile" />
                ) : (
                  <LoginPage showBackground={false} />
                )
              }
            />
            <Route
              path="/admin/login"
              exact
              element={
                isAuthenticated() ? (
                  <Navigate to="/profile" />
                ) : (
                  <AdminLoginPage showBackground={false} />
                )
              }
            />
            <Route
              path="/user/register"
              exact
              element={
                isAuthenticated() ? (
                  <Navigate to="/profile" />
                ) : (
                  <UserRegistrationPage showBackground={false} />
                )
              }
            />
            <Route
              path="/vendor/register"
              exact
              element={
                isAuthenticated() ? (
                  <Navigate to="/profile" />
                ) : (
                  <VendorRegistrationPage showBackground={false} />
                )
              }
            />
            {/* <Route path="/vendor/register" exact element={<RegistrationPage />} /> */}
            {/* <Route
              path="/item-search"
              exact
              element={
                <ProtectedRoute permissions={["user"]}>
                  <ItemSearch />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path="/admin/dashboard"
              exact
              element={
                <ProtectedRoute permissions={["admin", "Admin"]}>
                  <AdminReport />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path="/vendor/dashboard"
              exact
              element={
                <ProtectedRoute permissions={["vendor"]}>
                  <VendorReport />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              path="/user/dashboard"
              exact
              element={
                <ProtectedRoute permissions={["user"]}>
                  <UserReport />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/profile"
              exact
              element={
                <ProtectedRoute
                  permissions={["user", "admin", "Admin", "vendor"]}
                >
                  <ProfilePage showBackground={true} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact-us"
              exact
              element={<ContactPage showBackground={false} />}
            />
            <Route
              path="/about-us"
              exact
              element={<AboutUsPage showBackground={false} />}
            />
            {/* <Route path="/price" exact element={<PricingPage />} /> */}
            {/* <Route
              path="/item/search"
              exact
              element={
                <ProtectedRoute permissions={["user"]}>
                  <ItemSearch />
                </ProtectedRoute>
              }
            /> */}
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/payment-fail" element={<PaymentFail />} />
            <Route path="/payment-success" element={<PaymentSuccessful />} />
            <Route
              path="/forgot-password"
              element={<ForgotPassword showBackground={false} />}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/* <Route path="/expired" element={<Expired />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default AppRoutes;
