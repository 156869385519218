import React, { useState } from "react";
function RegisteredVendorsTable(props) {
  const { registeredVendorsData, blockpersonid, unblockpersonid } = props;
  const itemsPerPage = 25; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(registeredVendorsData.length / itemsPerPage);

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = registeredVendorsData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  return (
    <>
      <div className="card">
        <div id="registeredvendorstable" className="card-body p-3 0 my-3 mx-2">
          <h5 className="card-title">Audit Service Providers Registered:</h5>
          <table
            id="table"
            className="table table-responsive table-hover caption-top box-shadow-border-radius table-common-font-size"
          >
            {/* <caption>List of items</caption> */}
            <thead>
              <tr>
                <th scope="col" className="col-1 text-center">
                  S. No.
                </th>
                <th scope="col" className="col-1 text-center">
                  User Name
                </th>
                <th scope="col" className="col-1 text-center">
                  Email
                </th>
                <th scope="col" className="col-1 text-center">
                  Contact Number
                </th>
                <th scope="col" className="col-1 text-center">
                  Company Name
                </th>
                <th scope="col" className="col-1 text-center">
                  Job Role
                </th>
                <th scope="col" className="col-1 text-center">
                  Location
                </th>
                <th scope="col" className="col-1 text-center">
                  Country
                </th>
                {/* <th scope="col" className="col-1 text-center">
                  Subscription Plan
                </th> */}
                <th scope="col" className="col-1 text-center">
                  Block/Unblock
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index + 1}>
                  <td scope="row" className="col-1 text-center">
                    {indexOfFirstItem + index + 1}
                  </td>
                  <td className="col-1 text-center">{item.username}</td>
                  <td className="col-1 text-center">{item.email}</td>
                  <td className="col-1 text-center">{item.contact}</td>
                  <td className="col-1 text-center">{item.company}</td>
                  <td className="col-1 text-center">{item.jobRole}</td>
                  <td className="col-1 text-center">{item.location}</td>
                  <td className="col-1 text-center">{item.country}</td>
                  {/* <td className="col-1 text-center">{item.credits}</td> */}
                  {/* <td className="col-1 text-center">{item.active}</td> */}
                  <td className="col-1 text-center">
                    <button
                      className={`btn custom-btn ${
                        item.active ? "block" : "unblock"
                      }`}
                      onClick={() => {
                        item.active
                          ? blockpersonid(item.id)
                          : unblockpersonid(item.id);
                      }}
                    >
                      {item.active ? (
                        <>
                          <i className="fas fa-lock"></i> Block
                        </>
                      ) : (
                        <>
                          <i className="fas fa-unlock"></i> Unblock
                        </>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${
                    pageNum === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default RegisteredVendorsTable;
