import React, { useState, useEffect } from "react";
import { updateProfile } from "../../../services/profile";
import { toast } from "react-toastify";
import countries from "../../../data/Countries";
import { profileUploadService } from "../../../services/fileutility";
import { getAttributeInLocalStorageData } from "../../../utils";

function ProfileEditItem(props) {
  // Destructuring props
  const { name, company, jobRole, country, contact, email, companyBrief } =
    props;
  const userRole = getAttributeInLocalStorageData("role");
  // State variables
  const [formData, setFormData] = useState({
    jobRole: jobRole || "",
    company: company || "",
    country: country || "",
    contact: contact || "",
    companyBrief: companyBrief || "",
  });
  const [profileImage, setProfileImage] = useState(null); // Initialize with null

  // Update form data when props change
  useEffect(() => {
    setFormData({
      jobRole: jobRole || "",
      company: company || "",
      country: country || "",
      contact: contact || "",
      companyBrief: companyBrief || "",
    });
  }, [jobRole, company, country, contact, companyBrief]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedData = {
      ...formData,
    };
    updateProfile(updatedData)
      .then(() => {
        toast.success("Profile Updated Successfully");
      })
      .catch((error) => {
        toast.error(error.message || "Failed to update profile");
      });
  };

  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    setProfileImage(file); // Update the profileImage state with the selected file
  };

  const handleProfileUploadSubmit = async (event) => {
    event.preventDefault();
    if (profileImage) {
      console.log("profileImage" + profileImage.name.replace(/\.[^/.]+$/, ""));
      profileUploadService(profileImage)
        .then(() => {
          toast.success("Profile Uploaded Successfully");
        })
        .catch((error) => {
          toast.error(error.message || "Failed to upload profile");
        });
    } else {
      toast.error("Please select a file to upload");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      // Find the selected country based on the value (country code)
      const selectedCountry = countries.find(
        (country) => country.code === value
      );
      setFormData({
        ...formData,
        country: selectedCountry ? selectedCountry.name : "",
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="tab-pane fade profile-edit pt-3 ms-lg-4" id="profile-edit">
      {/* <!-- Profile Edit Form --> */}
      <form onSubmit={handleProfileUploadSubmit}>
        <div className="row mb-3">
          <label
            htmlFor="profileImage"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Profile Image
          </label>
          <div className="col-md-8 col-lg-9">
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="file"
                name="profileImage"
                accept="image/*, application/pdf" // Accept both image and PDF
                style={{ display: "none" }}
                onChange={handleProfileChange}
                id="profileImageInput"
              />
              <label
                htmlFor="profileImageInput"
                className="btn btn-danger btn-sm"
                title="Upload new profile image"
              >
                <i className="bi bi-upload" style={{ color: "white" }}></i>
              </label>
              {profileImage?.name ? (
                <span
                  className="ms-2"
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {profileImage.name}
                </span>
              ) : (
                <span className="ms-2">No file selected</span>
              )}
            </div>
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="btn btn-danger">
            Upload Profile Pic
          </button>
        </div>
      </form>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <label
            htmlFor="fullName"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Full Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              readOnly
              name="fullName"
              type="text"
              className="form-control"
              id="fullName"
              value={name || ""} // Provide a default value
            />
          </div>
        </div>

        <div className="row mb-3">
          <label htmlFor="company" className="col-md-4 col-lg-3 col-form-label">
            Company Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              onChange={handleChange}
              name="company"
              type="text"
              className="form-control"
              id="company"
              required
              value={formData.company}
            />
          </div>
        </div>

        <div className="row mb-3">
          <label htmlFor="jobRole" className="col-md-4 col-lg-3 col-form-label">
            Job
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              onChange={handleChange}
              name="jobRole"
              type="text"
              className="form-control"
              id="jobRole"
              required
              value={formData.jobRole}
            />
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="contactattribute"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Contact Number
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              onChange={handleChange}
              name="contact"
              type="text"
              className="form-control"
              id="contactattribute"
              value={formData.contact}
              minLength="8" // Minimum length to account for international format
              maxLength="15" // Maximum length for longer contact numbers
              pattern="^[+]?[0-9]+$" // Pattern to match numbers and optional plus sign
              title="Enter a valid contact number"
              required
            />
          </div>
        </div>
        {userRole === "vendor" && (
          <div className="row mb-3">
            <label
              htmlFor="companyBrief"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Company Brief
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="companyBrief"
                type="text"
                className="form-control"
                id="companyBrief"
                value={formData.companyBrief}
                title="Enter atleast 3 characters"
                required
              />
            </div>
          </div>
        )}

        <div className="row mb-3">
          <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">
            Email
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              readOnly
              name="email"
              type="email"
              className="form-control"
              id="Email"
              value={email || ""} // Provide a default value
            />
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-danger">
            Save Changes
          </button>
        </div>
      </form>
      {/* <!-- End Profile Edit Form --> */}
    </div>
  );
}

export default ProfileEditItem;
