import React, { useState, useEffect } from "react";

import ApexBarMarkerChart from "../subcomponents/chart-components/ApexBarMarkerChart";
import InquiriesMade from "../subcomponents/InquiriesMade";
import { toast } from "react-toastify";
import {
  mostInquiriedByDurationAndType,
  inquiriesMadeDatastats,
  countofinquiriesMadeDatastats,
} from "../../services/analytics";

function UserReport({ refreshInquiriesMadeData }) {
  const [inquiriesMadeData, setInquiriesMadeData] = useState([]);
  const [countOfinquiriesMadeData, setCountOfInquiriesMadeData] = useState([]);
  const [mostInquiredProduct, setMostInquiredProduct] = useState([]);
  const [mostInquiredVendor, setMostInquiredVendor] = useState([]);

  const [duration, setDuration] = useState(3); // Default to 3 months

  useEffect(() => {
    mostInquiriedByDurationAndType(duration, "product")
      .then((res) => {
        setMostInquiredProduct(res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    mostInquiriedByDurationAndType(duration, "vendor")
      .then((res) => {
        setMostInquiredVendor(res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    countofinquiriesMadeDatastats(duration)
      .then((res) => {
        setCountOfInquiriesMadeData(res.data);
        console.log("props.countOfinquiriesMadeData " + res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });
  }, [duration]);

  useEffect(() => {
    inquiriesMadeDatastats()
      .then((res) => {
        setInquiriesMadeData(res.data);
        console.log("props.inquiriesMadeDatastats " + res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });
  }, [refreshInquiriesMadeData]);

  const handleDurationChange = (months) => {
    setDuration(months);
    toast.success("Duration updated to " + months + " months");
  };

  return (
    <>
      {/* <section className="section p-0"> */}
      <div className="row mb-3">
        <div
          className="col-lg-12"
          style={{ display: "flex", justifyContent: "right" }}
        >
          <div className="btn-group" role="group" aria-label="Select Duration">
            {[3, 6, 9, 12].map((months) => (
              <button
                key={months}
                type="button"
                className={`btn custom-btn ${
                  duration === months ? "" : "selected-duration"
                }`}
                onClick={() => handleDurationChange(months)}
              >
                {months} Months
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="row">
          <div className="col-lg-12"> */}
      <div className="card">
        <div className="card-body p-3 0 my-3 mx-2">
          <h4 className="card-title mb-4">No. of inquiries made</h4>

          {countOfinquiriesMadeData.length === 0 ? (
            <h6 className="my-2"> No data available</h6>
          ) : (
            <ApexBarMarkerChart
              countOfinquiriesMadeData={countOfinquiriesMadeData}
            />
          )}
        </div>
      </div>

      <div className="card">
        <div className="card-body p-3 0 my-3 mx-2">
          <h4 className="card-title mb-4">
            Most inquiries made for Seller Name / Country
          </h4>
          {/* <!-- Default Table --> */}
          <table className="table table-responsive table-hover caption-top box-shadow-border-radius table-common-font-size">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col">Name</th>
                <th scope="col">Country</th>
                <th scope="col">Inquiry Count</th>
              </tr>
            </thead>
            <tbody>
              {mostInquiredVendor.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <h6 className="my-2"> No data available</h6>
                  </td>
                </tr>
              ) : (
                mostInquiredVendor.map((product, index) => (
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>{product.company}</td>
                    <td>{product.country}</td>
                    <td>{product.inquiryCount}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* <!-- End Default Table Example --> */}
        </div>
      </div>

      <div className="card">
        <div className="card-body p-3 0 my-3 mx-2">
          <h4 className="card-title mb-4">
            Most inquiries made for Product / Manufacturer
          </h4>

          {/* <!-- Default Table --> */}
          <table className="table table-responsive table-hover caption-top box-shadow-border-radius table-common-font-size">
            <thead>
              <tr>
                <th scope="col" clas>
                  S. No.
                </th>
                <th scope="col">Product</th>
                <th scope="col">Manufacturer</th>
                <th scope="col">Country</th>
                <th scope="col">Inquiry Count</th>
              </tr>
            </thead>
            <tbody>
              {mostInquiredProduct.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <h6 className="my-2"> No data available</h6>
                  </td>
                </tr>
              ) : (
                mostInquiredProduct.map((product, index) => (
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>{product.product}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.country}</td>
                    <td>{product.inquiryCount}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* <!-- End Default Table Example --> */}
        </div>
      </div>
      {/* </div>
        </div> */}

      {/* <div className="row">
          <div className="col-lg-12">
            <InquiriesMade inquiriesMadeData={inquiriesMadeData} />
          </div>
        </div> */}
      {/* </section> */}
    </>
  );
}

export default UserReport;
