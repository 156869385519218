import React from "react";
import { getAttributeInLocalStorageData } from "../../../utils";

function ProfileAboutItem(props) {
  const {
    name,
    company,
    jobRole,
    country,
    contact,
    email,
    username,
    companyBrief,
  } = props;
  const userRole = getAttributeInLocalStorageData("role");

  return (
    <div
      className="tab-pane fade show active profile-overview py-3 ms-lg-4"
      id="profile-overview"
    >
      {/* <h5 className="card-title">About</h5>
      <p className="small fst-italic">
        Sunt est soluta temporibus accusantium neque nam maiores cumque
        temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt
        iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed
        ea saepe at unde.
      </p> */}

      {/* <h3 className="card-title my-2">Profile Details</h3> */}

      <div className="row">
        <div className="col-lg-3 col-md-4 label ">User Name</div>
        <div className="col-lg-9 col-md-8">{username}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Email</div>
        <div className="col-lg-9 col-md-8">{email}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Company Name</div>
        <div className="col-lg-9 col-md-8">{company}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label ">Name</div>
        <div className="col-lg-9 col-md-8">{name}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Job Role</div>
        <div className="col-lg-9 col-md-8">{jobRole}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Contact Number</div>
        <div className="col-lg-9 col-md-8">{contact}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Country</div>
        <div className="col-lg-9 col-md-8">{country}</div>
      </div>

      {userRole === "vendor" && (
        <div className="row">
          <div className="col-lg-3 col-md-4 label">Company Brief</div>
          <div className="col-lg-9 col-md-8">{companyBrief}</div>
        </div>
      )}
    </div>
  );
}

export default ProfileAboutItem;
