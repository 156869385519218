import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../Header";
import PaymentComponent from "./PaymentComponent";

function PaymentFail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { orderId, paymentId, reason } = Object.fromEntries(searchParams);
  const [timer, setTimer] = useState(5);
  const countdown = useRef(null);
  const [enable, setEnable] = useState(false);
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    countdown.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown.current);
          setEnable(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown.current);
  }, []);

  return (
    <>
      <Header showBackground={false} />
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div
          className="card text-center shadow-lg p-4"
          style={{ maxWidth: "500px" }}
        >
          <div className="card-body">
            <div className="mb-3">
              <i className="bi bi-x-circle-fill text-danger display-1"></i>
            </div>
            <h3 className="text-danger fw-bold">Payment Failed!</h3>
            <p className="text-muted">
              Something went wrong with your transaction.
            </p>

            <div className="bg-light p-3 rounded text-start">
              <p className="mb-1">
                <strong>Order ID:</strong> {orderId}
              </p>
              <p className="mb-1">
                <strong>Payment ID:</strong> {paymentId}
              </p>
              <p className="mb-1">
                <strong>Reason:</strong> {reason}
              </p>
            </div>

            <button
              className="btn btn-danger w-100 mt-3"
              onClick={() => setRetry(true)}
              disabled={!enable}
            >
              Retry Payment {!enable ? `(${timer})` : ""}
            </button>

            <button
              className="btn btn-secondary w-100 mt-2"
              onClick={() => navigate("/contact-us")}
            >
              Contact Support
            </button>
          </div>
        </div>
      </div>
      {retry && <PaymentComponent orderDetails={{ orderId }} />}
    </>
  );
}

export default PaymentFail;
