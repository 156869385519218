import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  addCouponService,
  deleteCouponById,
  fetchAllCoupons,
  updateCoupon,
} from "../../../services/coupon";
function AddNewCoupon() {
  const initialFormData = {
    code: "",
    percentageOff: "",
  };

  const itemsPerPage = 25; // Number of items to display per page
  const [formData, setFormData] = useState(initialFormData);
  const [allCoupons, setAllCoupons] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(allCoupons.length / itemsPerPage);

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allCoupons.slice(indexOfFirstItem, indexOfLastItem);
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getAllCoupons = () => {
    fetchAllCoupons()
      .then((data) => {
        setAllCoupons(data);
      })
      .catch((error) => {
        toast.error("Error fetching plans:", error);
      });
  };
  useEffect(() => {
    getAllCoupons();
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", formData);
    addCouponService(formData)
      .then(() => {
        toast.success("Coupon Added Successfully");
        setFormData(initialFormData);
        getAllCoupons();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditChange = (attribute, value) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      [attribute]: value,
    }));
  };

  const handleEdit = (item) => {
    setEditItemId(item.id);
    setEditData({ ...item });
    setShowModal(true);
  };

  const handleSave = () => {
    // below snippet added for data save after adding popup model
    updateCoupon(editData, editItemId)
      .then(() => {
        toast.success("Report Updated Successfully");
        setAllCoupons((prevData) =>
          prevData.map((item) =>
            item.id === editItemId ? { ...item, ...editData } : item
          )
        );
        setEditItemId(null);
        setEditData({});
        setShowModal(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDelete = (editItemId) => {
    //make api call to delete the item
    editItemId &&
      deleteCouponById(editItemId)
        .then(() => {
          toast.success("Report Deleted Successfully");
          setEditData({});
          getAllCoupons();
        })
        .catch((error) => {
          toast.error(error);
        });
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-3 0 my-3 mx-2">
          <h5 className="card-title">Add New Coupon</h5>
          <form className="" onSubmit={handleFormSubmit}>
            <div className="row mb-3">
              <label
                htmlFor="name"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Coupon code
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  onChange={handleChange}
                  name="code"
                  type="text"
                  className="form-control"
                  id="code"
                  required
                  minLength={3}
                  value={formData.code}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label
                htmlFor="name"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Percentage Off
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  onChange={handleChange}
                  name="percentageOff"
                  type="number"
                  className="form-control"
                  id="percentageOff"
                  required
                  minLength={1}
                  min={0}
                  max={100}
                  value={formData.percentageOff}
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-danger">
                Add Coupon
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="card">
        <div id="couponslist" className="card-body p-3 0 my-3 mx-2">
          <h5 className="card-title">List of Coupons</h5>
          {allCoupons.length !== 0 ? (
            <table
              id="table"
              className="table table-responsive table-hover caption-top box-shadow-border-radius table-common-font-size"
            >
              <thead>
                <tr>
                  <th scope="col" className="col-1 text-center">
                    #
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Coupon Code
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Discount Percentage
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" className="col-1 text-center">
                      {index + 1}
                    </th>
                    <td className="col-2 text-center">{item.code}</td>
                    <td className="col-2 text-center">{item.percentageOff}</td>
                    <td className="col-2 text-center">
                      <i
                        className="bi bi-pencil me-4 text-warning"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEdit(item)}
                      ></i>
                      <i
                        className="bi bi-trash text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item.id)}
                      ></i>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1>No Coupons Published so far</h1>
          )}
        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${
                    pageNum === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Modal for Edit */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Audit Report</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Coupon Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.code || ""}
                    onChange={(e) => handleEditChange("code", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Discount Percentage</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.percentageOff || ""}
                    onChange={(e) =>
                      handleEditChange("percentageOff", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddNewCoupon;
