import React from "react";

const PackageOption = (props) => {
  const { title, europrice, inrprice, bullets, className, handleBuy } = props;

  return (
    <div className="col-lg-4 col-md-6">
      <div className={className} data-aos="zoom-in" data-aos-delay="100">
        <h3>{title}</h3>
        <h4>
          {europrice && (
            <>
              <sup>€</sup>
              {europrice}
            </>
          )}
          {europrice && inrprice && " / "}
          {inrprice && (
            <>
              <sup>₹</sup>
              {inrprice}
            </>
          )}
        </h4>
        <ul>
          {bullets.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </ul>
        <div className="btn-wrap">
          <a
            className="btn btn-danger px-5 rounded rounded-pill "
            onClick={handleBuy}
          >
            Buy Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default PackageOption;
