import React, { useEffect, useRef } from "react";
import { useRazorpay } from "react-razorpay";
import config from "../../../config";
import { getAttributeInLocalStorageData } from "../../../utils";
import { useNavigate } from "react-router-dom";

const PaymentComponent = ({ orderDetails }) => {
  const { error, isLoading, Razorpay } = useRazorpay();
  const navigate = useNavigate();
  const razorpayInstance = useRef();

  // Event handler for failed or canceled payments
  const handleFailedOrCancelledPayment = (response) => {
    console.log(response);
    navigate(
      "/payment-fail?orderId=" +
        response.error.metadata.order_id +
        "&paymentId=" +
        response.error.metadata.payment_id +
        "&reason=" +
        response.error.description
    );
  };

  useEffect(() => {
    console.log(orderDetails);

    handlePayment(
      orderDetails.orderId,
      orderDetails?.amount,
      orderDetails?.currency
    );

    // Add event listeners for failed/canceled/capture failed payments
    razorpayInstance.current.on(
      "payment.failed",
      handleFailedOrCancelledPayment
    );
    razorpayInstance.current.on(
      "payment.canceled",
      handleFailedOrCancelledPayment
    );
    razorpayInstance.current.on(
      "payment.capture.failed",
      handleFailedOrCancelledPayment
    );
  }, [orderDetails]);

  const handlePayment = async (orderId, amount, currency) => {
    const options = {
      key: config.payment.razorpay.keyId,
      amount: amount,
      currency: currency,
      name: "GMPauditreports.com",
      description: "Request by one of the vendor of GMP Audit Report",
      image: "https://gmpauditreports.com/assets/img/Logo-1.png",
      order_id: orderId,
      handler: (response) => {
        console.log(response);
        navigate(
          "/payment-success?orderId=" +
            response.razorpay_order_id +
            "&paymentId=" +
            response.razorpay_payment_id
        );
      },
      prefill: {
        name: getAttributeInLocalStorageData("name"),
        email: getAttributeInLocalStorageData("email"),
        contact: getAttributeInLocalStorageData("contact"),
      },
      theme: {
        color: "#F37254",
      },
    };
    console.log(options);
    razorpayInstance.current = new Razorpay(options);
    razorpayInstance.current.open();
  };

  return (
    <div>
      {/* {isLoading && <p>Loading Razorpay...</p>}
      {error && <p>Error loading Razorpay: {error}</p>} */}
    </div>
  );
};

export default PaymentComponent;
