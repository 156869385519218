import axios from "axios";
import API_URL from "../config/api";
import {
  getAttributeInLocalStorageData,
  getToken,
  setAttributeInLocalStorageData,
} from "../utils";

// sample user details
// {
//     "status": true,
//     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZSI6InVzZXIiLCJpYXQiOjE2OTI0MzM1OTQsImV4cCI6MTY5MzcyOTU5NH0.Osfw8azUGf0-xDXhEbj8OgOH9DRDPkth27JU0MXH6_s",
//     "id": 1,
//     "username": "user01",
//     "email": "ravindra.sannidhi1997@gmail.com",
//     "company": "Company pvt ltd",
//     "name": "GMP user",
//     "jobRole": "user",
//     "contact": "+919876543210",
//     "country": "India",
//     "role": "user",
//     "emailVerified": false
// }

export const adminLoginService = async (user) => {
  try {
    const res = await axios.post(API_URL.ADMIN_LOGIN, user);

    const existingData = JSON.parse(localStorage.getItem("data")) || {};
    existingData["token"] = res.data.token;
    await localStorage.setItem("data", JSON.stringify(existingData));
    console.log(
      "in the auth js " + JSON.parse(localStorage.getItem("data")).token
    );
    axios.defaults.headers.common["Authorization"] = `${res.data.token}`;
    setAttributeInLocalStorageData("id", res.data.id);
    setAttributeInLocalStorageData("username", res.data.username);
    setAttributeInLocalStorageData("email", res.data.email);
    setAttributeInLocalStorageData("company", res.data.company);
    setAttributeInLocalStorageData("name", res.data.name);
    setAttributeInLocalStorageData("jobRole", res.data.jobRole);
    setAttributeInLocalStorageData("contact", res.data.contact);
    setAttributeInLocalStorageData("country", res.data.country);
    setAttributeInLocalStorageData("role", res.data.role);
    setAttributeInLocalStorageData("emailVerified", res.data.emailVerified);

    return Promise.resolve(res.data);
  } catch (err) {
    const errorMessage = "Login failed";
    return Promise.reject(errorMessage);
  }
};

export const userLoginService = (user) => {
  return axios
    .post(API_URL.USER_LOGIN, user)
    .then((res) => {
      setAttributeInLocalStorageData("id", res.data.id);
      setAttributeInLocalStorageData("token", res.data.token);
      setAttributeInLocalStorageData("username", res.data.username);
      setAttributeInLocalStorageData("email", res.data.email);
      setAttributeInLocalStorageData("company", res.data.company);
      setAttributeInLocalStorageData("name", res.data.name);
      setAttributeInLocalStorageData("jobRole", res.data.jobRole);
      setAttributeInLocalStorageData("contact", res.data.contact);
      setAttributeInLocalStorageData("country", res.data.country);
      setAttributeInLocalStorageData("role", res.data.role);
      setAttributeInLocalStorageData("emailVerified", res.data.emailVerified);

      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const vendorLoginService = (vendor) => {
  console.log("vendor login service " + vendor);
  return axios
    .post(API_URL.VENDOR_LOGIN, vendor)
    .then((res) => {
      setAttributeInLocalStorageData("id", res.data.id);
      setAttributeInLocalStorageData("token", res.data.token);
      setAttributeInLocalStorageData("username", res.data.username);
      setAttributeInLocalStorageData("email", res.data.email);
      setAttributeInLocalStorageData("company", res.data.company);
      setAttributeInLocalStorageData("name", res.data.name);
      setAttributeInLocalStorageData("jobRole", res.data.jobRole);
      setAttributeInLocalStorageData("contact", res.data.contact);
      setAttributeInLocalStorageData("country", res.data.country);
      setAttributeInLocalStorageData("role", res.data.role);
      setAttributeInLocalStorageData("emailVerified", res.data.emailVerified);
      setAttributeInLocalStorageData("credits", res.data.credits);
      setAttributeInLocalStorageData("companyBrief", res.data.companyBrief);

      return res.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "Login failed";
      return Promise.reject(err);
    });
};

export const getVendorCredits = async () => {
  try {
    const res = await axios.get(API_URL.GET_VENDOR_CREDITS);
    setAttributeInLocalStorageData("credits", res.data.credits);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const adminRegisterService = (admin) => {
  return axios
    .post(API_URL.REGISTER_ADMIN, admin)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Admin Registration failed";
      return Promise.reject(errorMessage);
    });
};

export const userRegisterService = (user) => {
  return axios
    .post(API_URL.USER_REGISTER, user)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Registration failed";
      return Promise.reject(errorMessage);
    });
};

export const vendorRegisterService = (vendor) => {
  return axios
    .post(API_URL.VENDOR_REGISTER, vendor)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Registration failed";
      return Promise.reject(errorMessage);
    });
};

export const forgotPasswordService = (email) => {
  return axios
    .post(API_URL.FORGOT_PASSWORD, { email })
    .then((res) => res.data)
    .catch((err) => err?.response?.data);
};

export const resetPasswordService = (data) => {
  return axios
    .put(API_URL.RESET_PASSWORD, data)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);
};

export const sendVerificationEmail = (email) => {
  return axios
    .post(API_URL.SEND_VERIFICATION_MAIL, { email: email })
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Verification Email Send Failed";
      return Promise.reject(errorMessage);
    });
};

export const verifyemail = (token) => {
  return axios
    .post(API_URL.VERIFY_EMAIL, { token: token })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Email validation failed";
      return Promise.reject(errorMessage);
    });
};

export const forgotpassword = (data) => {
  return axios
    .post(API_URL.FORGOT_PASSWORD, data)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Verification Email Send Failed";
      return Promise.reject(errorMessage);
    });
};

export const resetPassword = (data) => {
  return axios
    .post(API_URL.RESET_PASSWORD, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Password Reset Failed";
      return Promise.reject(errorMessage);
    });
};

export const updatePassword = (data) => {
  return axios
    .post(API_URL.UPDATE_PASSWORD, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Password Update Failed";
      return Promise.reject(errorMessage);
    });
};

export const blockperson = (data) => {
  //data - id
  return axios
    .put(API_URL.BLOCK_PERSON + "/" + data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Blocking of user failed";
      return Promise.reject(errorMessage);
    });
};

export const unblockperson = (data) => {
  //data - id
  return axios
    .put(API_URL.UNBLOCK_PERSON + "/" + data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Unblocking of user failed";
      return Promise.reject(errorMessage);
    });
};
