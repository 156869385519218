import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

function WhyGMPSection() {
  const textaligncenter = { textAlign: "center" };
  return (
    <section
      className="plannewauditintro position-relative m-5 p-5 section-custom"
      id="plannewauditintro"
    >
      <div className="col-md-12 content bg-white d-flex justify-content-center flex-direction-column">
        <h3 className="align-center pb-3 text-align-center">
          Why GMPAuditReports.com?
        </h3>
        <p
          className="mbr-text align-center pb-4 text-align-center"
          style={{ textAlign: "justify" }}
        >
          <Link to={"/"}>GMPAuditReports.com </Link> platform connects audit
          report buyers, auditors and audit report sellers across the globe and
          makes it easier to buy or sell existing audit reports. Our platform
          gives instant access to the existing audit reports available with the
          audit service providers. The audit report buyers can search for the
          existing audit reports or plan a new audit with the best audit service
          providers to save time and manage suppliers effectively.
          GMPauditreports.com platform optimises your resources, budget and
          personnel in supplier qualification audits.
        </p>
      </div>
    </section>
  );
}

export default WhyGMPSection;
