import React, { useState } from "react";
import { toast } from "react-toastify";
import { updatePassword } from "../../../services/auth";

function ProfileChangePasswordItem() {
  const initialFormData = {
    oldPassword: "",
    newPassword: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Prepare the data to send via API call

    updatePassword(formData)
      .then(() => {
        toast.success("Password Updated Successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="tab-pane fade pt-3 ms-lg-4" id="profile-change-password">
      {/* <!-- Change Password Form --> */}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <label
            htmlFor="currentPassword"
            className="col-md-4 col-lg-3 col-form-label fw-bold"
          >
            Current Password
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="oldPassword"
              type="password"
              className="form-control"
              id="oldPassword"
              required
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="newPassword"
            className="col-md-4 col-lg-3 col-form-label fw-bold"
          >
            New Password
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="newPassword"
              type="password"
              className="form-control"
              id="newPassword"
              required
              minLength={9}
              maxLength={20}
              onChange={handleChange}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$"
              title="Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 9 characters long."
            />
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="renewPassword"
            className="col-md-4 col-lg-3 col-form-label fw-bold"
          >
            Re-enter New Password
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="renewpassword"
              type="password"
              className="form-control"
              id="renewPassword"
              required
              pattern={`^${formData.newPassword}$`}
              title="Passwords must match."
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-danger">
            Change Password
          </button>
        </div>
      </form>
      {/* <!-- End Change Password Form --> */}
    </div>
  );
}

export default ProfileChangePasswordItem;
