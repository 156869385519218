import React, { useState } from "react";
import formatDate from "../../utils/dateUtility";
import countries from "../../data/Countries";
function InquiriesMade(props) {
  const { inquiriesMadeData } = props;
  const itemsPerPage = 25; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(inquiriesMadeData.length / itemsPerPage);

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = inquiriesMadeData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  return (
    <>
      <div className="card">
        <div
          id="registeredvendorstable"
          className="card-body p-3 0 my-3 mx-2 table-common-font-size"
        >
          <h4 className="card-title mb-5">Details of Inquiries Made</h4>
          <table
            id="table"
            className="table table-responsive table-hover caption-top box-shadow-border-radius table-common-font-size"
          >
            {/* <caption>List of items</caption> */}
            <thead>
              <tr>
                <th scope="col" className="col-1 text-left">
                  S. No.
                </th>
                <th scope="col" className="col-1 text-left">
                  Date of inquiry
                </th>
                <th scope="col" className="col-3 text-left">
                  Product Name
                </th>
                <th scope="col" className="col-3 text-left">
                  Manufacturer Name
                </th>
                <th scope="col" className="col-2 text-left">
                  Unit Number
                </th>
                <th scope="col" className="col-1 text-left">
                  Location
                </th>
                <th scope="col" className="col-1 text-left">
                  Country
                </th>

                {/* <th scope="col" className="col-1 text-left">
                  message
                </th> */}
              </tr>
            </thead>
            <tbody>
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-left">
                    <h6 className="my-2"> No data available</h6>
                  </td>
                </tr>
              ) : (
                currentItems.map((item, index) => (
                  <tr key={index + index}>
                    <td scope="row" className="col-1 text-left">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="col-1 text-left">
                      {formatDate(item.inquiryDate)}
                    </td>
                    <td className="col-3 text-left">{item.productName}</td>
                    <td className="col-3 text-left">{item.manufacturerName}</td>
                    <td className="col-2 text-left">{item.unitNumber}</td>
                    <td className="col-1 text-left">{item.location}</td>
                    <td className="col-1 text-left">
                      {countries.find(
                        (country) => country.code === item.country
                      )?.name || item.country}
                    </td>

                    {/* <td className="col-1 text-left">{item.message}</td> */}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${
                    pageNum === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default InquiriesMade;
