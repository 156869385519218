import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../Header";
import { setAttributeInLocalStorageData } from "../../../utils";
import { getVendorCredits } from "../../../services/auth";
// import "bootstrap-icons/font/bootstrap-icons.css"; // Ensure Bootstrap Icons are available

function PaymentSuccessful() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Extract params from URL
  const { orderId, paymentId } = Object.fromEntries(searchParams); // Destructure params
  const [timer, setTimer] = useState(5);
  const countdown = useRef(null);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    countdown.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown.current);
          setEnable(true);
        }
        return prev - 1;
      });
    }, 1000);

    return async () => {
      await getVendorCredits();
      clearInterval(countdown.current); // Clear on unmount
    };
  }, []);

  return (
    <>
      <Header showBackground={false} />
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div
          className="card text-center shadow-lg p-4"
          style={{ maxWidth: "500px" }}
        >
          <div className="card-body">
            <div className="mb-3">
              <i className="bi bi-check-circle-fill text-success display-1"></i>
            </div>
            <h3 className="text-success fw-bold">Payment Successful!</h3>
            <p className="text-muted">
              Your transaction was completed successfully.
            </p>

            <div className="bg-light p-3 rounded text-start">
              <p className="mb-1">
                <strong>Order ID:</strong> {orderId}
              </p>
              <p className="mb-1">
                <strong>Payment ID:</strong> {paymentId}
              </p>
            </div>

            {/* <p className="text-muted mt-3">Redirecting in {timer} seconds...</p> */}

            <button
              className="btn btn-danger w-100 mt-3"
              onClick={() => navigate("/profile")}
              disabled={!enable}
            >
              Back to Profile {!enable ? `(${timer})` : ""}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSuccessful;
