import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header";
function PageNotFound() {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      navigate("/");
    }
  }, [timer, navigate]);

  return (
    <>
      <Header showBackground={false} />

      <section className="pagenotfound" id="pagenotfound">
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <div className="col-md-4">
            <div className="border border-3 border-danger"></div>
            <div className="card bg-white shadow p-5">
              <div className="mb-4 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="75"
                  height="75"
                  fill="currentColor"
                  className="bi bi-exclamation-circle text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 0 8 8A8 8 0 0 0 8 0zm0 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-.75-3.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0zm.25-5.5a.25.25 0 0 1 .25.25v4a.25.25 0 1 1-.5 0v-4a.25.25 0 0 1 .25-.25z" />
                </svg>
              </div>
              <div className="text-center">
                <h1>Page Not Found</h1>
                <p>The requested page could not be found.</p>
                <Link
                  to="/"
                  className="btn btn-outline-danger"
                  style={{ color: "white" }}
                >
                  Go Back Home
                </Link>
                <div className="mt-3">Redirecting in {timer} seconds...</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PageNotFound;
