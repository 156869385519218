import React, { useState, useEffect } from "react";
import { fetchallplans, purchasePlan } from "../../services/plan";
import { toast } from "react-toastify";
import { getAttributeInLocalStorageData } from "../../utils";
import { fetchCouponByName } from "../../services/coupon";
import PaymentComponent from "./payment-components/PaymentComponent";
import PriceBanner from "./PriceBanner";
import MainPricingSection from "../homepage-section-components/MainPricingSection";

function PublishedPlans() {
  const itemsPerPage = 25; // Number of items to display per page

  const [plans, setplans] = useState([]);
  const [showPaymentComponent, setShowPaymentComponent] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState({});
  // const [responseState, setResponseState] = React.useState([]);

  const initialFormData = {
    code: "",
    originalPrice: "",
    discountedPercentage: "",
    updatedPrice: "",
    planid: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false);
  const [couponApply, setCouponApply] = useState(false);
  const [validCoupon, setValidCoupon] = useState("not fetched");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  useEffect(() => {
    // axios call
    fetchallplans()
      .then((data) => {
        setplans(data); // Update the state with fetched data
      })
      .catch((error) => {
        toast.error("Error fetching plans:", error);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  // const totalPages = Math.ceil(plans.length / itemsPerPage);

  // // Get the current items to display on the current page
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = plans.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  // const handlePageChange = (pageNumber) => {
  //   if (pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  const handlePackageBuy = (plan) => {
    setShowModal(true);
    setFormData({ ...formData, originalPrice: plan.price });
    setFormData({ ...formData, originalPrice: plan.price, planid: plan.id });
  };

  const handleFormDataEditChange = (attribute, value) => {
    setFormData((prevEditData) => ({
      ...prevEditData,
      [attribute]: value,
    }));
  };

  const handleCloseModal = (e) => {
    // e.preventDefault();
    setShowModal(false);
    setAcceptedTerms(false);
    setShowPaymentComponent(false);
    setOrderDetails({});
  };

  const handlevalidateCouponSubmit = (e) => {
    e.preventDefault();
    //validate coupon code
    fetchCouponByName(formData.code)
      .then((res) => {
        setFormData({
          ...formData,
          discountedPercentage: res.percentageOff ? res.percentageOff : 0,
        });
        setCouponApply(true);
        setValidCoupon("valid");
      })
      .catch((error) => {
        toast.error(error);
        setFormData({ ...formData, discountedPercentage: 0 });
        setValidCoupon("invalid");
      });
  };

  const proceedToBuy = () => {
    purchasePlan(formData.planid, formData.code)
      .then((res) => {
        // handleRazorpayScreen(res.orderId,res.amount,res.currency)
        setShowPaymentComponent(true);
        setOrderDetails({
          orderId: res.orderId,
          amount: res.amount * 100,
          currency: res.currency,
        });

        // toast.success("Plan Purchase Successfully");
      })
      .catch((error) => {
        setShowPaymentComponent(false);
        toast.error(error);
        setOrderDetails({});
      });
  };

  return (
    <>
      <div className="">
        <div id="registeredvendorstable" className="p-3 0 my-3 mx-2">
          {/* <h5 className="card-title">Available Plans</h5> */}
          <h1>
            {/* abcd {currentItems} - {plans} - {publishedAuditReports.length} */}
          </h1>

          {plans.length !== 0 ? (
            <>
              {" "}
              {/* <table
                id="table"
                className="table table-responsive table-hover caption-top box-shadow-border-radius"
              >
                <thead>
                  <tr>
                    <th scope="col" className="col-1 text-center">
                      #
                    </th>
                    <th scope="col" className="col-2 text-center">
                      Plan Name
                    </th>
                    <th scope="col" className="col-2 text-center">
                      Credits
                    </th>
                    <th scope="col" className="col-1 text-center">
                      Price
                    </th>
                    <th scope="col" className="col-1 text-center">
                      Currency
                    </th>
                    {getAttributeInLocalStorageData("role") === "vendor" && (
                      <th scope="col" className="col-1 text-center">
                        Buy
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row" className="col-1 text-center">
                        {item.id}
                      </th>
                      <td className="col-2 text-center">{item.name}</td>
                      <td className="col-2 text-center">
                        {item?.credits || "default"}
                      </td>
                      <td className="col-2 text-center">{item.price}</td>
                      <td className="col-1 text-center">{item.currency}</td>
                      {getAttributeInLocalStorageData("role") === "vendor" && (
                        <td className="col-1 text-center">
                          <button
                            onClick={() => handlePackageBuy(item)}
                            className="btn btn-danger"
                          >
                            Buy
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table> */}
              <>
                <p
                  className="btn btn-danger"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTarget"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  View pricing <i className="fa-solid fa-chevron-down"></i>
                </p>
                <div className="collapse" id="collapseTarget">
                  <div className="card card-body">
                    {/* <PriceBanner /> */}
                    <MainPricingSection
                      plans={plans}
                      handleBuy={handlePackageBuy}
                    />{" "}
                  </div>
                </div>
              </>
            </>
          ) : (
            <h1>No Reports Published so far</h1>
          )}
        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          {/* <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${
                    pageNum === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul> */}
        </nav>
      </div>

      {showModal && (
        <div className="modal blur show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Buy plan</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => handleCloseModal()}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <form className="" onSubmit={handlevalidateCouponSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="code"
                          type="text"
                          className="form-control"
                          id="code"
                          // required
                          minLength={3}
                          value={formData.code}
                          placeholder="Coupon Code"
                          onChange={(e) =>
                            handleFormDataEditChange("code", e.target.value)
                          }
                          // disabled={couponApply}
                        />
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <button type="submit" className="btn btn-danger">
                          Apply
                        </button>
                      </div>
                    </div>
                  </form>

                  <div className="row mb-3">
                    <div className="d-flex">
                      {validCoupon === "valid" ? (
                        <>
                          <i className="fa-solid fa-check text-success"></i>
                          <h6 className="ps-2 text-success">
                            {" "}
                            Coupon applied successfully
                          </h6>
                        </>
                      ) : (
                        validCoupon === "invalid" && (
                          <>
                            <i className="fa-solid fa-circle-xmark text-danger"></i>
                            <h6 className="ps-2 text-danger">
                              {" "}
                              Invalid coupon
                            </h6>
                          </>
                        )
                      )}
                    </div>
                    <div className="mt-3 col-md-6 col-lg-6">
                      <label>Price</label>
                    </div>
                    <div className="mt-3 col-md-6 col-lg-6">
                      {formData.originalPrice}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6 col-lg-6">
                      <label>{`Discount (${formData.discountedPercentage}%)`}</label>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      {formData.discountedPercentage
                        ? (
                            (formData.originalPrice *
                              formData.discountedPercentage) /
                            100
                          ).toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6 col-lg-6">
                      <label>Total </label>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      {formData.discountedPercentage
                        ? (
                            (formData.originalPrice *
                              (100 - formData.discountedPercentage)) /
                            100
                          ).toFixed(2)
                        : formData.originalPrice.toFixed(2)}
                    </div>
                  </div>
                  <>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value={acceptedTerms}
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                      required
                    />
                    I have read and agree to the{" "}
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/dev-entropy-391816.appspot.com/o/Policies.pdf?alt=media&token=0e4457a9-973f-4144-95a9-cc89e568dddb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and conditions
                    </a>
                  </>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleCloseModal()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={proceedToBuy}
                  disabled={!acceptedTerms}
                >
                  Proceed to Buy
                </button>
              </div>
              {showPaymentComponent && (
                <PaymentComponent orderDetails={orderDetails} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublishedPlans;
