import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import Header from "../Header";
import { Link, useLocation } from "react-router-dom";
import { userLoginService, vendorLoginService } from "../../services/auth";
import { toast } from "react-toastify";
import { useAuth } from "../AuthContext";

function LoginPage({ showBackground }) {
  const { setIsLoggedIn, setUserRole } = useAuth();

  const location = useLocation();

  const [role, setRole] = useState(location.state?.role || "user");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: role,
    recaptchaString: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (role === "user") {
      userLoginService(formData)
        .then(() => {
          toast.success("Login successful! Redirecting to profile...");
          setUserRole((prevValue) => "user");
          setIsLoggedIn((prevValue) => true);
          console.log("setis logged in true");

          console.log("set user role to user");
          // navigate("/profile");
          window.location.href = "/profile";
        })
        .catch((error) => {
          toast.error(error.response?.data?.message ?? "Something went wrong!");
        });
    } else if (role === "vendor") {
      vendorLoginService(formData)
        .then(() => {
          setIsLoggedIn(true);
          setUserRole("vendor");
          toast.success("Login successful! Redirecting to profile...");
          window.location.href = "/profile";
        })
        .catch((error) => {
          // Handle error
          toast.error(error.response.data.message);
        });
    }
  };

  return (
    <>
      <Header showBackground={showBackground} />
      {/* Section: Design Block */}
      <section
        id="login"
        className="background-radial-gradient overflow-hidden"
      >
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1
                className="my-5 display-5 fw-bold ls-tight"
                style={{ color: "rgb(255, 255, 255)" }}
              >
                <span style={{ color: "rgb(255, 255, 255)" }}>Welcome to</span>
                <br />
                GMP Audit Reports
              </h1>
              {/* <p
                className="mb-4 opacity-70"
                style={{ color: "hsl(218, 81%, 85%)" }}
              >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Temporibus, expedita iusto veniam atque, magni tempora mollitia
                dolorum consequatur nulla, neque debitis eos reprehenderit quasi
                ab ipsum nisi dolorem modi. Quos?
              </p> */}
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <div className="mb-4">
                      <span>I am a</span>
                      <div className="form-check">
                        <input
                          type="radio"
                          id="user"
                          value="user"
                          name="role"
                          checked={formData.role === "user"}
                          onChange={(event) => {
                            setFormData({
                              ...formData,
                              role: event.target.value,
                            });
                            setRole("user");
                          }}
                          className="form-check-input ms-4"
                        />
                        <label htmlFor="user" className="form-check-label ms-2">
                          Report Buyer
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          id="vendor"
                          value="vendor"
                          name="role"
                          checked={formData.role === "vendor"}
                          onChange={(event) => {
                            setFormData({
                              ...formData,
                              role: event.target.value,
                            });
                            setRole("vendor");
                          }}
                          className="form-check-input ms-4"
                        />
                        <label
                          htmlFor="vendor"
                          className="form-check-label ms-2"
                        >
                          Audit Service provider
                        </label>
                      </div>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                        User Name
                      </label>
                      <input
                        type="text"
                        id="username"
                        className="form-control"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>

                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-outline mb-4 captcha-container">
                      <ReCAPTCHA
                        sitekey={config.googleRecaptchaSiteKey}
                        onChange={(value) =>
                          setFormData({ ...formData, recaptchaString: value })
                        }
                      />
                    </div>

                    {/* <!-- Submit button --> */}
                    <button
                      type="submit"
                      className="btn btn-danger btn-block mb-4"
                      disabled={
                        !(
                          formData.role &&
                          formData.password &&
                          formData.username &&
                          formData.recaptchaString
                        )
                      }
                    >
                      Login
                    </button>
                    <div className="form-outline mb-4">
                      <Link className="text-primary" to="/forgot-password">
                        Forgot your Password?
                      </Link>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section: Design Block */}
    </>
  );
}

export default LoginPage;
